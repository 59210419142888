import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";

import {Navbar} from './components/Navbar';
import {Uploader} from './components/Uploader';
import {Scan} from "./components/Scan";
import {Search} from "./components/Search";
import {Explore} from "./components/Explore";

export function App() {
	return (
		<Router>
			<Navbar />
				<Routes>
					<Route path="/file/:sha256" element={<Scan />} />
					<Route exact path="/home" element={<Uploader />} />
					<Route exact path="/search" element={<Search />} />
					<Route exact path="/search/:q" element={<Search />} />
					<Route exact path="/explore" element={<Explore />} />
					<Route path="*">
						<Navigate to="/home"  replace/>
					</Route>
				</Routes>
		</Router>
	);
}
